import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FaFileAlt } from "react-icons/fa"

import styles from "../styles/institucional.module.scss"

import Layout from "../components/Layout"
import CallToAction from "../components/CallToAction"

const Institutional = () => {
  const data = useStaticQuery(graphql`
    query {
      getCoverImage: file(relativePath: { eq: "institucional/cover.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      getAtas: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(atas)/.*\\\\.md$/" } }
      ) {
        edges {
          node {
            frontmatter {
              date
              title
              file
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div className={styles.cover}>
        <Img
          fluid={data.getCoverImage.childImageSharp.fluid}
          alt="Paisagem da freguesia de Touro"
          style={{ height: "100%", width: "100%" }}
          imgStyle={{ objectFit: "cover" }}
        />
        <h1>Institucional</h1>
      </div>
      <div className={styles.membersWrapper}>
        <div className={styles.members}>
          <div className={styles.council}>
            <h2>Membros Junta</h2>
            <div>
              <p>Mário Morgado</p>
              <p>Presidente</p>
              <p>Jorge João Pires Morais</p>
              <p>Tesoureiro</p>
              <p>Sandra Isabel da C. S. Morais</p>
              <p>Secretário</p>
            </div>
          </div>
          <div className={styles.assembly}>
            <h2>Membros Assembleia</h2>
            <div className={styles.assemblyInnerWrapper}>
              <div>
                <p>Cassiano Afonso Rosa</p>
                <p>Presidente</p>
                <p>Mário Pires do Amaral</p>
                <p>Primeiro Secretário</p>
                <p>Maria de Lurdes A. B. Costa</p>
                <p>Segundo Secretário</p>
              </div>
              <div>
                <p>João David Duarte Ramos</p>
                <p>Membro</p>
                <p>Helena Morais Lopes</p>
                <p>Membro</p>
                <p>Amândio Martinho Salvador</p>
                <p>Membro</p>
              </div>
              <div>
                <p>Armando da Silva Pires</p>
                <p>Membro</p>
                <p>Sónia Carina Martins Gomes</p>
                <p>Membro</p>
                <p>Daniel Rebelo dos Santos</p>
                <p>Membro</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.documentsWrapper}>
        <div className={styles.documents}>
          <h2>Atas</h2>
          {!!data.getAtas.edges.length &&
            data.getAtas.edges.map((edge, i) => {
              const { date, title, file } = edge.node.frontmatter
              console.log("file", file)
              return (
                <div key={`${title}-${i}`} className={styles.document}>
                  <div className={styles.documentName}>
                    <FaFileAlt size="1.75rem" />
                    <p>{title}</p>
                  </div>
                  <div className={styles.documentDate}>
                    <p>
                      {new Date(date).toLocaleString("pt-PT", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </p>
                  </div>
                  <div className={styles.documentDownload}>
                    <a href={file}>Descarregar</a>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      <CallToAction
        title="Necessita de submeter um Requerimento?"
        buttonLabel="Submeter Requerimento"
        url={"/novo-requerimento"}
      />
    </Layout>
  )
}

export default Institutional
